// @import "flex";
@import "~react-image-gallery/styles/css/image-gallery.css";

.heading {
  background-color: #41414d;
  // border-bottom: #41414d 1px solid;
  color: rgb(212, 211, 209);
}

.nav {
  font-size: 13px;
  background-color: rgb(212, 211, 209);

  .nav-link {
    color: rgba(29, 29, 29, 0.568);
    padding: 8px 16px !important;
  }
  .nav-link:active {
    color: black;
  }

  .nav-link:hover {
    color: black;
  }
}
.container-fluid {
  text-align: left;
  font-size: 13px;
}

._loading_overlay_overlay {
  z-index: 11111;
  height: 100%;
  .css-42igfv {
    position: fixed !important;
    left: 50%;
    top: 45%;
    @media (max-width: 767px) {
      left: 40%;
      top: 50%;
    }
  }
}

.All-Style,
.Web-Product,
.Web-Variations,
.All-In-Stock-Serials {
  border-bottom: #e3e3e3 1px solid;
  border-left: #e3e3e3 1px solid;
  border-right: #e3e3e3 1px solid;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;

  .Fields-Container {
    margin: 0 !important;
    padding: 20px 10px 4px 25px !important;
    // width: 100%;
    > .Search-fields-Col {
      // padding: 0 10px 0 10px;
      width: 20%;
      margin: 0;
      > .row {
        padding: 0 0 0.7rem 0;
        margin: 0;
        width: auto;
        label {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
    > .Search-fields-Col:first-child {
      padding: 0 10px 0 0px;
      .Select-Brand {
        width: 100%;
        display: flex;
        height: 30px;
        h2 {
          height: 100%;
          width: 25%;
          font-size: 12px;
          color: #58585a;
          line-height: 30px;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 0 0 0 8px;
        }
        ul {
          height: 100%;
          list-style: none;
          padding: 0;
          margin: 0;
          width: 65%;
          overflow: hidden;

          li {
            display: inline-block;
            width: 50%;
            label::before {
              border: 1px solid #595959;
              box-sizing: content-box;
              content: "";
              color: #0b6aff;
              margin-right: calc(16px * 0.5);
              top: 50%;
              left: 0;
              width: 17px;
              height: 14px;
              display: inline-block;
              vertical-align: middle;
              margin-top: 2px;
            }
            label::after {
              top: 10.2px;
              left: 2.4px;
              /* margin: 5px 2px 2px 3px; */
              width: 11.5px;
              height: 11.5px;
            }
          }
          // li:first-child {
          //   width: 40%;
          // }
          // li:nth-child(2) {
          //   width: 60%;
          // }
        }
      }
      .form-field {
        display: flex;
        width: 100%;
        height: 30px;
        > h2 {
          height: 100%;
          width: 26%;
          font-size: 12px;
          color: #58585a;
          line-height: 30px;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 0 0 0 8px;
        }

        > div {
          width: 65%;
          height: 100%;
          > h2 {
            font-size: 14px;
          }
          > input {
            font-size: 13px;
            height: 30px;
            background-color: #fff;
            padding-right: 5px;
            height: 100%;
          }
          > button {
            font-size: 13px;
            color: #58585a;
            min-height: 25px;
            height: 100%;
            padding: 5px;
            > div {
              padding: 4px;
              width: 100%;
            }
          }
          > ul {
            top: 30px;
            max-height: 250px;
            input {
              font-size: 13px;
            }
            > li {
              font-size: 12px;
              line-height: 14px;
              padding: 4px 10px !important;
            }
          }
        }
        > div {
          > div {
            > div {
              position: absolute;
              top: 0;
              padding-top: 2px;
            }
          }
        }

        .css-cfyl0p-InputWrapper,
        input {
          min-height: auto !important;
          height: 23px;
          font-size: 13px;
          width: 100%;
        }
        svg {
          height: 10px;
          margin-top: 3px;
        }
      }
      .form-field.text-red {
        h2 {
          color: red;
        }
      }
    }
    > .Search-fields-Col:nth-child(2) {
      padding: 0 10px 0 10px;
      .form-field {
        display: flex;
        width: 100%;
        height: 30px;
        > h2 {
          width: 30%;
          font-size: 12px;
          color: #58585a;
          line-height: 30px;
          margin: 0px;
          display: block;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%;
          // padding: 0 0 0 8px;
        }

        > div {
          width: 65%;
          height: 100%;
          > h2 {
            font-size: 14px;
          }
          > input {
            font-size: 13px;
            height: 30px;
            background-color: #fff;
            padding-right: 5px;
            height: 100%;
          }
          > button {
            font-size: 13px;
            color: #58585a;
            min-height: 25px;
            height: 100%;
            padding: 5px;
            > div {
              padding: 4px;
              width: 100%;
            }
          }
          > ul {
            top: 30px;
            max-height: 250px;
            input {
              font-size: 13px;
            }
            > li {
              font-size: 12px;
              line-height: 14px;
              padding: 4px 10px !important;
            }
          }
        }
        > div {
          > div {
            > div {
              position: absolute;
              top: 0;
              padding-top: 2px;
            }
          }
        }
      }
      .price-range {
        margin-bottom: 0px;
        height: 30px;
        width: 100%;
        .price-range__input {
          display: flex;
          > div {
            width: 30%;
            > div {
              > input {
                background: #fff;
                padding: 5px;
                color: #58585a;
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                  height: 25px;
                }
                font-size: 10px;
                font-weight: bold;
              }
            }
            > h2 {
              font-size: 12px;
              color: #58585a;
              line-height: 20px;
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // padding: 0 0 0 8px;
            }
          }
          .price-range__input--price-input {
            width: 65%;
            height: 30px;
            input {
              min-height: 100% !important;
            }
          }
        }
      }
      .form-field.text-red {
        h2 {
          color: red;
        }
      }
      .price-range__input.text-red {
        div {
          h2 {
            color: red;
          }
        }
      }
    }
    > .Search-fields-Col:nth-child(3) {
      padding: 0 10px 0 10px;
      .form-field {
        display: flex;
        width: 100%;
        height: 30px;
        > h2 {
          height: 100%;
          width: 35%;
          font-size: 12px;
          color: #58585a;
          line-height: 30px;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 0 0 0 8px;
        }

        > div {
          width: 65%;
          height: 100%;
          > h2 {
            font-size: 14px;
          }
          > input {
            font-size: 13px;
            height: 30px;
            background-color: #fff;
            padding-right: 5px;
            height: 100%;
          }
          > button {
            font-size: 13px;
            color: #58585a;
            min-height: 25px;
            height: 100%;
            padding: 5px;
            > div {
              padding: 4px;
              width: 100%;
            }
          }
          > ul {
            top: 30px;
            max-height: 250px;
            input {
              font-size: 13px;
            }
            > li {
              font-size: 12px;
              line-height: 14px;
              padding: 4px 10px !important;
            }
          }
        }
        > div {
          > div {
            > div {
              position: absolute;
              top: 0;
              padding-top: 2px;
            }
          }
        }
      }
      .price-range {
        margin-bottom: 0px;
        height: 30px;
        .price-range__input {
          display: flex;
          > div {
            width: 35%;
            > div {
              > input {
                background: #fff;
                padding: 5px;
                color: #58585a;
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                  height: 25px;
                }
                font-size: 10px;
                font-weight: bold;
              }
            }
            > h2 {
              height: 100%;
              font-size: 12px;
              color: #58585a;
              line-height: 20px;
              display: block;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // padding: 0 0 0 8px;
            }
          }
          .price-range__input--price-input {
            width: 65%;
            height: 30px;
            input {
              min-height: 100% !important;
            }
          }
        }
      }
      .form-field.text-red {
        h2 {
          color: red;
        }
      }
      .price-range__input.text-red {
        div {
          h2 {
            color: red;
          }
        }
      }
    }
    > .Search-fields-Col:nth-child(4) {
      padding: 0 0px 0 10px;
      .form-field {
        display: flex;
        width: 100%;
        height: 30px;
        > h2 {
          height: 100%;
          width: 36%;
          font-size: 12px;
          color: #58585a;
          line-height: 30px;
          margin: 0px;
          display: block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 0 0 0 8px;
        }

        > div {
          width: 58%;
          height: 100%;
          > h2 {
            font-size: 14px;
          }
          > input {
            font-size: 13px;
            height: 30px;
            background-color: #fff;
            padding-right: 5px;
            height: 100%;
          }
          > button {
            font-size: 13px;
            color: #58585a;
            min-height: 25px;
            height: 100%;
            padding: 5px;
            > div {
              padding: 4px;
              width: 100%;
            }
          }
          > ul {
            top: 30px;
            max-height: 250px;
            input {
              font-size: 13px;
            }
            > li {
              font-size: 12px;
              line-height: 14px;
              padding: 4px 10px !important;
            }
          }
        }
        > div {
          > div {
            > div {
              position: absolute;
              top: 0;
              padding-top: 2px;
            }
          }
        }
        input {
          min-height: auto !important;
          height: 23px;
          font-size: 13px;
        }
        svg {
          height: 10px;
          margin-top: 3px;
        }
      }
      .checkbox-Col {
        width: 40%;
        padding: 0;
        .toggle__filters {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            input[type="checkbox"] {
              margin-right: 7px;
              margin-top: 4px;
              cursor: pointer;
            }
            li {
              display: flex;
              .toggle--label {
                font-size: 11px;
                color: #58585a;
                line-height: 20px;
                margin: 0px;
                // font-weight: 600;
              }
            }
            li:first-child {
              display: none;
            }
          }
        }
        .toggle__filters.text-red {
          li {
            span {
              color: red;
            }
          }
        }
      }
      .form-field.text-red {
        h2 {
          color: red;
        }
      }
    }
  }
  > .row:nth-child(2) {
    // text-align: center;
    padding: 4px 45% 8px 45%;
    margin: 0 !important;
  }
}

label {
  font-size: 12px;
  color: #58585a;
  font-weight: bold;
  // line-height: 30px;
}

.Result-Row {
  padding: 15px 0 5px 0 !important;
  margin: 0 0 0 0 !important;
  .row {
    margin: 0 !important;
    padding: 0 !important;
  }
  > .card {
    width: 100%;
    height: 275px;
    background-color: aliceblue;
    margin-top: 5px;
    > .row {
      padding: 5px !important;
      margin: 0 !important ;
      height: 100%;
      > div {
        width: auto;
        // padding: 6px !important;
        margin: 0px 2.5px !important;
        height: 100%;
      }
      > div.item_no {
        // padding: 100px 2px 2px 2px;
        display: grid;
        padding: 0;
        span {
          margin: auto;
        }
      }
      > div.item_image_container {
        border: #e3e3e3 0.5px solid;
        height: 100%;
        padding: 0px 0px 0px 0px !important;
        .item_image_links {
          height: 10%;
          display: flex;
          flex-direction: row-reverse;
          .web-image-icon {
            width: 23px;
            float: right;
            cursor: pointer;
            margin: 2px;
          }
        }
        .item_image {
          display: grid;
          height: 90%;

          .item_image_icon {
            max-height: 100%;
            max-width: 100%;
            margin: auto;
          }
          .product-image-icon {
            max-width: 100%;
            max-height: 100%;
            /*            min-height: 100px;*/
            margin: auto;
          }
        }
      }
      > div.item_short_details {
        // width: 18%;
        max-width: 19%;
        border: #e3e3e3 0.5px solid;
        padding: 3px 2px 3px 8px;
        background-color: #fff;

        label {
          width: 100%;
          font-weight: bold;
          font-size: 10px;

          // white-space: nowrap;
          span {
            font-weight: normal;
            font-size: 10px;
            // white-space: nowrap;
          }
        }
      }
      > div.web_control {
        width: 23% !important;
        border: #e3e3e3 0.5px solid;
        padding: 3px 2px 3px 8px;
        background-color: #fff;
        > div.web_control_header {
          margin: 0 !important;
          //  padding: 0 !important;
          padding: 0 28% !important;

          span {
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            // width: 100%;
            padding: 2px 20px;
            // position: absolute;
            // top: -1px;
            // left: 20%;
            margin: -5px 0px 3px 0px;
            background-color: aliceblue;
          }
        }
        > .row {
          padding: 0px 0px 6px 0px !important;
        }
        > div.sort_priority {
          padding: 0px 0px 0px 0px;
          margin: 0;
          display: flex;
          flex-direction: row;
          // flex-wrap: wrap;
          div.final_sort {
            width: 60%;
            label {
              width: 100%;
              font-weight: bold;
              font-size: 11px;
              margin: 0;
              span {
                font-weight: normal;
                font-size: 11px;
              }
            }
          }
          div.priority {
            display: flex;
            align-items: center;
            width: 30%;
            label {
              width: 50%;
              font-size: 11px;
              margin: 0;
              font-weight: normal;
              padding: 0px 0px 0px 10px;
              // padding-top: 3px;
            }
            select {
              width: 50%;
              font-size: 9px;
              height: auto;
              // line-height: 9px;
              color: #4c515a;
              // margin: 5px 0px;
              padding: 2px 0px;
              // width: 60%;
            }
          }
        }
        > div.web_control_checkboxes {
          padding: 0px 0px 0px 0px;
          margin: 0;
          column-gap: 2px;
          .form-check {
            position: relative;
            display: flex;
            /* padding-left: 1.25rem; */
            align-items: center;
            column-gap: 3px;
            margin-bottom: 2px;
            padding: 0;

            input[type="checkbox"] {
              position: relative;
              margin: 0;
              padding: 0;
              cursor: pointer;
            }
            label {
              font-size: 10px;
              white-space: nowrap;
              font-weight: normal;
            }
          }
        }
        > div.web_product_group,
        div.style_group,
        div.parent_style_number {
          display: flex;
          padding: 0px 0px 0px 0px;
          margin: 0;
          align-items: center;
          column-gap: 4px;
          span {
            width: 32%;
            font-size: 11px;
            // padding-top: 5px;
          }
          select {
            width: 65%;
            font-size: 9px;
            height: auto;
            line-height: 9px;
            color: #4c515a;
            // margin: 5px 0px;
            padding: 2px 0px;
            // width: 60%;
          }
        }
        > div.master_parent_style {
          padding: 0px 0px 0px 0px;
          margin: 0;
          .form-check {
            display: flex !important;
            align-items: center !important;
            padding: 0px 0px 0px 0px;
            column-gap: 4px;
            .form-check-input {
              margin: 0;
              position: relative;
            }
            label {
              font-size: 10px;
              font-weight: normal;
            }
          }
        }
        // > div.parent_style_number {
        //   display: flex;
        //   margin: 0;
        //   padding: 0px 0px 0px 0px;
        //   align-items: center;
        //   column-gap: 4px;
        //   span {
        //     width: 32%;
        //     font-size: 11px;
        //     // padding-top: 5px;
        //   }
        //   select {
        //     font-size: 9px;
        //     height: auto;
        //     color: #4c515a;
        //     // margin: 5px 0px;
        //     line-height: 12px;
        //     padding: 2px 0px;
        //     width: 65%;
        //   }
        // }
        > div.web_control_save {
          margin: 0;
          padding: 4px 0px 0px 0px !important;
          display: grid;

          button {
            width: 20%;
            height: 100%;
            font-size: 12px;
            padding: 1px 10px;
            // margin-top: -2px;
            // margin-left: 10px;
            margin: auto;
          }
        }
      }
      > div.tags {
        padding: 0 !important;
        width: 14%;
        border: #e3e3e3 0.5px solid;
        background-color: #fff;
        > div.tag_header {
          margin: 0 !important;
          //  padding: 0 !important;
          padding: 0 32% !important;

          span {
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            // width: 100%;
            padding: 2px 20px;
            // position: absolute;
            // top: -1px;
            // left: 20%;
            margin-top: -2px;
            background-color: aliceblue;
          }
        }

        > div.select_tags {
          margin: 0px !important;
          // position: absolute;
          // top: 12%;
          width: 100%;
          padding: 0px 5% !important;
          select {
            font-size: 11px;
            height: 100px;
            color: #4c515a;
            margin: 5px 0px;
            line-height: 12px;
            padding: 2px 0px;
            width: 100%;
            // width: 60%;
          }
        }
        > div.tags_selected {
          width: 100%;
          margin: 0 !important;
          // position: absolute;
          // top: 25%;
          padding: 4px !important;
          display: flex;
          flex-direction: row;
          height: 93px;
          overflow-y: auto;
          align-items: baseline;
          align-content: flex-start;
          overflow-x: hidden;

          label {
            font-size: 9px;
            background-color: rgb(232, 235, 238);
            padding: 2px 7px;
            font-weight: bold;
            margin-right: 3px;
            line-height: 11px;
            height: auto;
            white-space: normal;
            max-height: 100%;
          }
          i {
            font-weight: lighter;
            font-size: 9px;
            margin: 2px 0 0 5px;
            cursor: pointer;
          }
        }
        > div.save_tags {
          margin: 7px 0 0 0 !important;
          // position: absolute;
          // top: 220px;
          padding: 0 !important;
          display: grid;
          // flex-direction: row;
          // right: 30px;
          width: 100%;
          button {
            font-size: 12px;
            width: 30%;
            height: 100%;
            padding: 1px 10px;
            margin: auto;
            // margin-top: -2px;
            // margin-left: 20%;
          }
        }
      }
      > div.item_details {
        width: 32%;
        padding: 3px 2px 3px 8px;
        border: #e3e3e3 0.5px solid;
        background-color: #fff;
        div.details_header {
          margin: 0 !important;
          //  padding: 0 !important;
          padding: 0 40% !important;
          height: 10%;

          span {
            font-size: 13px;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            // width: 100%;
            padding: 2px 20px;
            // position: absolute;
            // top: -1px;
            // left: 20%;
            margin: -5px 0px 3px 0px;
            background-color: aliceblue;
          }
        }
        div.details {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          row-gap: 5px;
          width: 100%;
          height: 90%;
          div.row {
            width: 50%;
            // height: 11px;
            // font-size: 10px !important;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            span:first-child {
              width: 41%;
              font-size: 9px;
              font-weight: bold;
            }
            span:nth-child(2) {
              font-size: 9px;
              width: 59%;
              padding: 0px 0px 0px 4px;
              white-space: normal;
            }
          }
        }

        // > .row {
        //   margin: 0;
        //   padding: 0;
        //   // > div:first-child {
        //   //   width: 50%;
        //   //   height: 100%;
        //   //   padding: 0;
        //   //   span {
        //   //     font-size: 10px;
        //   //     font-weight: bold;
        //   //     width: 100%;
        //   //     display: block;
        //   //     padding: 2px 5px;
        //   //     margin: 2px;
        //   //     white-space: nowrap;
        //   //   }
        //   // }
        //   // > div:nth-child(2) {
        //   //   width: 50%;
        //   //   height: 100%;
        //   //   padding: 0;
        //   //   span {
        //   //     font-size: 10px;
        //   //     font-weight: bold;
        //   //     width: 100%;
        //   //     display: block;
        //   //     padding: 2px 5px;
        //   //     margin: 2px;
        //   //     white-space: nowrap;
        //   //   }
        //   // }
        //   // > div:nth-child(3) {
        //   //   width: 23%;
        //   //   height: 100%;
        //   //   padding: 0;
        //   //   span {
        //   //     font-size: 11px;
        //   //     font-weight: lighter;
        //   //     width: 100%;
        //   //     display: block;
        //   //     padding: 2px 5px;
        //   //   }
        //   // }
        //   // > div:nth-child(4) {
        //   //   width: 27%;
        //   //   height: 100%;
        //   //   padding: 0;
        //   //   span {
        //   //     font-size: 11px;
        //   //     font-weight: bold;
        //   //     width: 100%;
        //   //     display: block;
        //   //     padding: 2px 5px;
        //   //   }
        //   // }
        // }
      }
    }
  }
}
span {
  font-size: 12px;
  font-weight: normal;
}

.es-product-loader {
  text-align: center;
  padding: 5em;
  font-size: 22px;
}

.image-modal {
  z-index: 10000;
  cursor: pointer;
  .modal-dialog {
    @media (max-width: 767px) {
      min-width: 95%;
      max-height: 90%;
    }
    .modal-content {
      border-radius: 0;
      border: 1px solid grey;
      .modal-body {
        min-height: 40vh;
        .image-gallery-image {
          max-height: 68vh;
          min-height: 68vh;
        }
        .image-gallery-thumbnails-container {
          float: left;
          padding: 10px;
          .image-gallery-thumbnail {
            width: 80px;
          }
        }
      }
    }
  }
  .modal-header {
    border-bottom: 0;
    padding: 15px 15px 0px 15px;
    .close:focus {
      outline: none;
    }
    button.close {
      // font-size: 12px;
      padding: 16px 16px 16px 10px;
      span:first-child {
        font-size: 20px;
      }
    }
    .download--btn {
      font-size: 14px;
      margin: 2px 7px;
      border: 1.5px solid #58585a;
      padding: 5px 10px;
      border-radius: 0px;
      color: #ffffff;
      background-color: #8a8a8e;
      outline: none;
    }
  }
  .image-modal-con {
    text-align: center;
  }
}

.iframeModal {
  z-index: 10000;
  cursor: pointer;
  max-height: 100%;
  .modal-dialog {
    width: 95vw;
    @media (max-width: 767px) {
      width: 100%;
      max-height: 90%;
    }
  }
  .modal-body {
    padding: 4px 0px;
  }
  iframe {
    width: 100%;
    min-height: 85vh;
    @media (max-width: 767px) {
      min-height: 75vh;
    }
    .button {
      height: 24px !important;
    }
    .main {
      height: 100%;
    }
  }
}

.Toastify__toast-container {
  z-index: 10021 !important;
}
.css-1mnns6r {
  position: relative;
}
.Toastify__toast {
  min-height: 54px !important;
}
.Toastify__toast--default {
  background: #cecece !important;
  color: #3c3c3c !important;
}
